@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'pop';
    src: url('./Assets/StarcruiserExpandedSemiItalic-gKeY.ttf')  format('truetype');
}

@keyframes movingGradient {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.scratch-effect {
  clip-path: polygon(0 0, 0% 100%, 100% 0); /* Adjust the polygon points as per your needs */
  transition: clip-path 1s ease-in-out;
}

.scratch-effect-active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}



@keyframes breathing {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.01);
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }